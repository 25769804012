export default class URLConfig {
  /**
   *服务器IP
   *
   * @static
   * @memberof NetWork
   */
  // static IP = 'https://120.27.131.157:443/app/'
  // static IP = 'http://172.17.6.253:9010/app/'
  static IP = 'https://www.gdzj.org.cn/app/'

  /**
*登陆接口
*
* @static
* @memberof URLConfig
*/
  static loginSecurity = this.IP + "LEMS_SERVICE/api/rest/user/loginSecurity"
  /**
   *获取工单列表
   *
   * @static
   * @memberof URLConfig
   */
  static getOrderList = this.IP + "LEMS_SERVICE/app/rest/order/getOrderList"

  /**
   *查询是否存在未处理为审核的工单
   *
   * @static
   * @memberof URLConfig
   */
  static getOrderDateCount = this.IP + "LEMS_SERVICE/app/rest/order/getOrderDateCount"

  /**
   *处理预警
   *
   * @static
   * @memberof URLConfig
   */
  static updateOrder = this.IP + "LEMS_SERVICE/app/rest/order/updateOrder"
  /**
   *新建工单
   *
   * @static
   * @memberof URLConfig
   */
  static addOrder = this.IP + 'LEMS_SERVICE/app/rest/order/addOrder'
  /**
   *环境品质列表
   *
   * @static
   * @memberof URLConfig
   */
  static getEnvironmentList = this.IP + 'LEMS_SERVICE/app/rest/environment/getEnvironmentList'
  /**
 *能源统计
 *
 * @static
 * @memberof URLConfig
 */
  static getEnergyCount = this.IP + 'LEMS_SERVICE/app/rest/environment/getEnergyCount'
  /**
*环境统计
*
* @static
* @memberof URLConfig
*/
  static getWarningType = this.IP + 'LEMS_SERVICE/app/rest/environment/getWarningType'
  /**
*维保列表
*
* @static
* @memberof URLConfig
*/
  static getMaintenanceList = this.IP + 'LEMS_SERVICE/app/rest/application/getMaintenanceList'
  /**
*新建维保记录
*
* @static
* @memberof URLConfig
*/
  static addMaintenance = this.IP + 'LEMS_SERVICE/app/rest/application/addMaintenance'
  /**
*巡检列表
*
* @static
* @memberof URLConfig
*/
  static getInspectionList = this.IP + 'LEMS_SERVICE/app/rest/application/getInspectionList'

  /**
*新建巡检记录
*
* @static
* @memberof URLConfig
*/
  static addInspection = this.IP + 'LEMS_SERVICE/app/rest/application/addInspection'

  /**
*维修列表
*
* @static
* @memberof URLConfig
*/
  static getRepairList = this.IP + 'LEMS_SERVICE/app/rest/application/getRepairList'
  /**
*新建维修
*
* @static
* @memberof URLConfig
*/
  static addRepair = this.IP + 'LEMS_SERVICE/app/rest/application/addRepair'

  /**
*装修列表
*
* @static
* @memberof URLConfig
*/
  static getDecorationList = this.IP + 'LEMS_SERVICE/app/rest/application/getDecorationList'
  /**
*新建装修
*
* @static
* @memberof URLConfig
*/
  static addDecoration = this.IP + 'LEMS_SERVICE/app/rest/application/addDecoration'
  /**
*知识库列表
*
* @static
* @memberof URLConfig
*/
  static getKnowledgeList = this.IP + 'LEMS_SERVICE/app/rest/application/getKnowledgeList'

  /**
*新建知识库
*
* @static
* @memberof URLConfig
*/
  static addKnowledge = this.IP + 'LEMS_SERVICE/app/rest/application/addKnowledge'
  /**
*权限列表
*
* @static
* @memberof URLConfig
*/
  static getAuthorList = this.IP + 'LEMS_SERVICE/app/rest/setting/getAuthorList'

  /**
*访客列表
*
* @static
* @memberof URLConfig
*/
  static getVisitorsList = this.IP + 'LEMS_SERVICE/app/rest/setting/getVisitorsList'

  /**
*个人信息
*
* @static
* @memberof URLConfig
*/
  static getUserInfo = this.IP + 'LEMS_SERVICE/app/rest/setting/getUserInfo'

  /**
*获取对象系统
*
* @static
* @memberof URLConfig
*/
  static getOrderSys = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getOrderSys'

  /**
*获取所有的诊断等级
*
* @static
* @memberof URLConfig
*/
  static getOrderLevel = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getOrderLevel'

  /**
*处理维保工单
*
* @static
* @memberof URLConfig
*/
  static updateMaintenance = this.IP + 'LEMS_SERVICE/app/rest/application/updateMaintenance'

  /**
*处理巡检工单
*
* @static
* @memberof URLConfig
*/
  static updateInspection = this.IP + 'LEMS_SERVICE/app/rest/application/updateInspection'

  /**
*处理巡检工单
*
* @static
* @memberof URLConfig
*/
  static updateRepair = this.IP + 'LEMS_SERVICE/app/rest/application/updateRepair'

  /**
*获取维修设备列表
*
* @static
* @memberof URLConfig
*/
  static getDevice = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getDevice'

  /**
*处理装修工单
*
* @static
* @memberof URLConfig
*/
  static updateDecoration = this.IP + 'LEMS_SERVICE/app/rest/application/updateDecoration'

  /**
*上传图片
*
* @static
* @memberof URLConfig
*/
  static uploadFile = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/uploadFile'

  /**
*下载图片
*
* @static
* @memberof URLConfig
*/
  static getFile = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getFile'

  /**
*获取工单数量
*
* @static
* @memberof URLConfig
*/
  static getMessageCount = this.IP + 'LEMS_SERVICE/app/rest/setting/getMessageCount'

  /**
*获取工单消息列表
*
* @static
* @memberof URLConfig
*/
  static getMessageList = this.IP + 'LEMS_SERVICE/app/rest/setting/getMessageList'

  /**
*获取二维码详情
*
* @static
* @memberof URLConfig
*/
  static getPicCodeList = this.IP + 'LEMS_SERVICE/app/rest/setting/getPicCodeList'

  /**
*获取巡更列表
*
* @static
* @memberof URLConfig
*/
  static getPatrolList = this.IP + 'LEMS_SERVICE/app/rest/application/getPatrolList'

  /**
*获取巡更记录
*
* @static
* @memberof URLConfig
*/
  static getPatroLoglList = this.IP + 'LEMS_SERVICE/app/rest/application/getPatroLoglList'

  /**
*获取巡更审批记录
*
* @static
* @memberof URLConfig
*/
  static getPatrolBatchList = this.IP + 'LEMS_SERVICE/app/rest/application/getPatrolBatchList'

  /**
*巡更审批
*
* @static
* @memberof URLConfig
*/
  static electronicPatrolBatchUpdate = this.IP + 'LEMS_SERVICE/app/rest/application/electronicPatrolBatchUpdate'

  /**
*扫一扫
*
* @static
* @memberof URLConfig
*/
  static patroLogUpdate = this.IP + 'LEMS_SERVICE/app/rest/application/patroLogUpdate'

  /**
*获取用户权限
*
* @static
* @memberof URLConfig
*/
  static getAuthority = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getAuthority'

  /**
*获取楼层列表
*
* @static
* @memberof URLConfig
*/
  static getDictList = this.IP + 'LEMS_SERVICE/api/rest/publicInterface/getDictList'

  /**
*发送短信
*
* @static
* @memberof URLConfig
*/
  static sedMessage = this.IP + 'LEMS_SERVICE/api/rest/user/sedMessage'


}