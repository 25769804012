<template>
  <div class="PageContent">
    <!-- <canvas ref="canvas"
            class="CanvasStyle"></canvas> -->

    <!--图片展示-->
    <div class="PaiZhaoView"
         v-show="!IsShowClickImage && !isShowImage">
      <video ref="video"
             style="width: 100%; height: 100%;"
             autoplay></video>
      <label class="ShowText"
             v-show="!isShowPaiShaoButton">点击我开始拍照</label>
      <div class="ButtonView"
           v-show="isShowPaiShaoButton">
        <el-button icon="el-icon-close"
                   circle
                   @click="ClosePageClick"></el-button>
        <el-button icon="el-icon-camera-solid"
                   circle
                   @click="photograph"></el-button>
      </div>
    </div>

    <!--canvas截取流-->
    <div class="ShowImageView"
         v-show="isShowImage">
      <canvas ref="canvas"
              width="712"
              height="1000"></canvas>
      <div class="ButtonView">
        <el-button icon="el-icon-close"
                   circle
                   @click="CloseImageClick"></el-button>
        <el-button icon="el-icon-check"
                   circle
                   @click="OkImageClick"></el-button>
      </div>
    </div>

    <!--展示图片-->
    <div class="ShowImageView"
         v-show="IsShowClickImage">
      <img :src="ShowImageData"
           :style="{width:width+'px',height:Height+'px'}"></img>
      <div class="ButtonView">
        <el-button icon="el-icon-close"
                   circle
                   @click="CloseShowImageClick"></el-button>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'

export default {
  props: {
    IsShowClickImage: {
      type: Boolean,
      default: false
    },
    ShowImageData: {
      type: String,
      default: ''
    }
  },
  components: {
    NavigationView
  },
  data: function () {
    return {
      width: window.innerWidth,
      Height: window.innerHeight,
      isShowImage: false,
      isShowPaiShaoButton: false,
      ImageData: ''
    }
  },
  mounted: function () {
    if (!this.IsShowClickImage) {
      this.openPhoto();
    }
  },
  methods: {
    //结束展示照片
    CloseShowImageClick: function () {
      this.$emit('ChoseImageBlock');
    },
    //结束拍照
    ClosePageClick: function () {
      debugger
      this.$emit('ChoseImageBlock');
    },
    //重新拍照
    CloseImageClick: function () {
      this.isShowImage = false;
    },
    //拍照完成
    OkImageClick: function () {
      this.$emit('EndImageBlock', this.ImageData);
      this.ShowState = false;
    },
    // 打开摄像头
    openPhoto () {
      let self = this;
      // H5调用电脑摄像头API
      //navigator.mediaDevices.getUserMedia 调用后 会提示用户给予使用媒体输入的许可.返回一个 Promise 对象，
      //成功后会resolve回调一个 MediaStream 对象。
      //在H5设备上面调用摄像头也可以通过此种方式，如下的例子表示优先使用前置摄像头
      //{ audio: true, video: { facingMode: "user" } }前摄像头
      //{ audio: true, video: { facingMode: { exact: "environment" } } }强制使用后置摄像头
      //设置获取接近 1280x720 的相机分辨率
      //{ audio: true, video: { width: 1280, height: 720 } };
      navigator.mediaDevices
        .getUserMedia({
          audio: false, video: { facingMode: { exact: "environment" }, }
        })
        .then((success) => {
          // alert('成功');
          // 摄像头开启成功
          self.$refs["video"].srcObject = success; //srcObject 是实时流
          // 实时拍照效果
          let videoElement = self.$refs["video"];
          videoElement.addEventListener("click", function () {
            videoElement.play();
            self.isShowPaiShaoButton = true;
          });
        })
        .catch((error) => {
          debugger
          // alert('失败');
          //摄像头开启失败
          console.error("摄像头开启失败");
        });
    },
    photograph () {
      let photoInfo = this.$refs["canvas"].getContext("2d");
      this.$refs["canvas"].style.width = this.width + 'px';
      this.$refs["canvas"].style.height = this.Height + 'px';
      // 把当前内容渲染到canvas上
      photoInfo.drawImage(this.$refs["video"], 0, 0);
      //canvas图片 转base64格式、图片格式转换、图片质量压缩
      let imgBase64 = this.$refs["canvas"].toDataURL("image/jpeg", 2.7);
      let string = imgBase64.replace("data:image/jpeg;base64,", "");
      this.ImageData = string;
      let strLgh = string.length;
      let fileLgh = parseInt(strLgh - (strLgh / 8) * 2); //
      let size = (fileLgh / 1024).toFixed(2);// 由字节转换为KB 判断大小
      console.log(size); //图片大小
      this.isShowImage = true;
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}
.CanvasStyle {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.ShowImageView {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.PaiZhaoView {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.ShowText {
  position: absolute;
  bottom: 50px;
  transform: translate(-50%);
  left: 50%;
  top: 50%;
  pointer-events: none;
}
.ButtonView {
  position: absolute;
  bottom: 50px;
  transform: translate(-50%);
  left: 50%;
}
</style>