<template>
  <div class="BacePageView">
    <NavigationView :IsBack="false"
                    :IsCode="false"
                    Title='设置'></NavigationView>
    <div class="PageContent">
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="ItemClick(0)">
            权限管理
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="ItemClick(1)">
            访客管理
          </div>
        </div>
      </div>
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="ItemClick(2)">
            个人信息
          </div>
        </div>
        <div class="CarView">

        </div>
      </div>
      <div class="PhoneView">
        <label @click="CallPhone('0571-87286139')">服务热线:0571-87286139(办公室)</label>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
export default {
  components: {
    NavigationView
  },
  data: function () {
    return {

    }
  },
  methods: {
    CallPhone: function (phoneNumber) {
      if (phoneNumber != '--') {
        window.location.href = 'tel://' + phoneNumber
      }
    },
    //模块点击
    ItemClick: function (type) {
      //权限管理
      if (type == 0) {
        this.$router.push({ name: 'QuanXianPage' })
      }

      //访客管理
      if (type == 1) {
        this.$router.push({ name: 'FangKeGuanLi' })
      }

      //个人信息
      if (type == 2) {
        this.$router.push({ name: 'PeopleContent' })
      }
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
}
.GridCell {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-evenly;
}
.CellJianGe {
  height: 100%;
  width: 10%;
}
.CarView {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 30%;
}
.Content {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(187, 187, 187, 100);
  color: rgba(98, 93, 93, 100);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-semiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PhoneView{
  margin-top: 30px;
  color:rgb(80, 80, 204);
}
</style>