<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='知识库'></NavigationView>
    <div class="PageContent">
      <div class="ListView">

        <van-pull-refresh v-model="refreshing"
                          @refresh="onRefresh">
          <van-list v-model:loading="loading"
                    :finished="finished"
                    @load="onLoad">

            <div class="CellView"
                 v-for="(item,index) in list"
                 :key="index"
                 @click="ZhiShiContent(item)">
              <div class="CellContent">
                <div class="Cell_Title">
                  {{jianCeObject.GetValue(item.knowledgeName)}}
                </div>
              </div>

            </div>

          </van-list>
        </van-pull-refresh>

      </div>
      <div class="AddGongDan"
           @click="CreateGongDan">
        <img style="width:20px;height:20px"
             :src="addSrc" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView,
  },
  data: function () {
    return {
      addSrc: window.RootPath + 'Images/Add.png',
      refreshing: false,
      loading: false,
      finished: true,
      list: [
      ],//列表数据源
      jianCeObject: ValueJianCe
    }
  },
  mounted: function () {
    this.InitEvent();
    //获取网络数据
    this.GetListData();
  },
  methods: {
    //注册事件
    InitEvent: function () {
      let self = this;
      this.$EventBus.$off('ReloadData-ZhiShiKu');
      this.$EventBus.$on('ReloadData-ZhiShiKu', () => {
        self.GetListData();
      });
    },
    ZhiShiContent: function (item) {
      this.$router.push({
        name: 'ZhiShiPageConent', params: {
          item: item
        }
      })
    },
    //获取网络数据
    GetListData: function () {
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this;
      NetWork.Post(URLConfig.getKnowledgeList, {
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.list = res.data.items;
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    },
    //上拉加载
    onLoad: function () {
      this.loading = false;
      this.finished = true;
    },
    //下拉刷新
    onRefresh: function () {
      this.refreshing = false;
    },
    //创建工单
    CreateGongDan: function () {
      this.$router.push({ name: 'ZhiShiPageCreate' })
    }
  }
}
</script>

<style scoped>
.PaiXuView {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(187, 187, 187, 100);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
}
.ListView {
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-basis: 0;
}
.AddGongDan {
  position: absolute;
  right: 16px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
}
.CellView {
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 100%;
  border-bottom: 1px solid rgba(187, 187, 187, 0.2);
}
.StateView {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #e54d42;
}
.StateView_1 {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #636363;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border-top: 1px solid rgba(187, 187, 187, 0.2);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 46px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
  position: absolute;
  right: 16px;
}
.PaiXuView_Right {
  flex: 1;
}
</style>