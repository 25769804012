<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='权限管理'></NavigationView>
    <div class="PageContent">
      <div class="HuanJingContent">
        <el-table :data="tableData"
                  style="width: 100%"
                  :default-sort="{prop: 'date', order: 'descending'}">
          <el-table-column prop="jobNo"
                           label="工号">
          </el-table-column>
          <el-table-column prop="personName"
                           label="姓名"
                           width="100">
          </el-table-column>
          <el-table-column prop="author"
                           label="权限"
                           width="100">
          </el-table-column>
          <el-table-column prop="remak"
                           label="备注"
                           width="100">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'
export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      active: 0,
      tableData: []
    }
  },
  mounted: function () {
    //获取网络数据
    this.GetListData();
  },
  methods: {
    //获取网络数据
    GetListData: function () {
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this; debugger
      NetWork.Post(URLConfig.getAuthorList, {
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.tableData = res.data.items;
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
}
.HuanJingContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(187, 187, 187, 100);
}
</style>