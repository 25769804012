<template>
  <div class="BacePageView LoginBackView">
    <div class="CenterView">
      <label class="Title">国家毒品实验室浙江分中心</label>
      <label class="Title_1">实验室APP</label>
      <div class="InputView">
        <label class="UserTitle">用户名:</label>
        <el-input style="height:45px"
                  v-model="UserName"
                  placeholder="请输入账号"></el-input>
      </div>
      <div class="InputView">
        <label class="UserTitle">密码:</label>
        <el-input v-model="UserPwd"
                  type="password"
                  placeholder="请输入密码"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  element-loading-text="正在登录"
                  element-loading-background="rgba(0, 0, 0, 0.2)"></el-input>
      </div>
      <div class="InputView">
        <label class="UserTitle">验证码:</label>
        <div class="YanZhengCode">
          <el-input v-model="PhoneCode"
                    placeholder="请输入验证码"
                    v-loading.fullscreen.lock="fullscreenLoading"
                    element-loading-text="正在登录"
                    element-loading-background="rgba(0, 0, 0, 0.2)"></el-input>
          <input class="CodeButton"
                 type="button"
                 :value="CodeButtonName"
                 @click="GetCode" />
        </div>

      </div>
      <input class="LoginButtonView"
             type="button"
             value="登录"
             @click="Login" />
    </div>
  </div>
</template>

<script>
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import GlobleClass from '@/components/ToolClass/GlobleClass'
import axios from "axios";

export default {
  components: {
  },
  data: function () {
    return {
      // UserName: "17610276613",//审批人-18084764335-处理人-18084764336
      // UserPwd: "Zy@123456",
      UserName: "",
      UserPwd: "",
      fullscreenLoading: false,
      CodeButtonName: '获取验证码',
      IsGetCode: false,//是否点击获取过验证码
      PhoneCode: ''//验证码
    }
  },
  methods: {
    //获取验证码
    GetCode: function () {
      if (this.CodeButtonName !== '获取验证码') {
        this.$message.error('请不要频繁发送');
        return;
      }
      if (this.UserName === '') {
        this.$message.error('请先输入用户名称');
        return;
      }
      //标记已经获取过验证码
      this.IsGetCode = true;
      //开始倒计时
      let self = this;
      let index = 120;
      let timer = setInterval(() => {
        self.CodeButtonName = index + '秒';
        if (index > 1) {
          index--;
        } else {
          clearInterval(timer);
          self.CodeButtonName = '获取验证码'
        }
      }, 1000);
      //调用短信接口
      NetWork.Post(URLConfig.sedMessage, {
        telephone: this.UserName,
        type: '2'
      }, (res) => {

      }, () => {

      })
    },
    //登陆
    Login: function () {
      //验证用户信息
      if (this.UserName === '') {
        this.$message.error('请输入用户名称');
        return;
      }
      if (this.UserPwd === '') {
        this.$message.error('请输入用户密码');
        return;
      }
      if (!this.IsGetCode) {
        this.$message.error('请先获取验证码');
        return;
      }
      if (this.PhoneCode == '' || this.PhoneCode == null) {
        this.$message.error('请输入验证码');
        return;
      }
      //验证码的合法性
      let reg = /^[\d]+$/;
      if (!reg.test(this.PhoneCode) || this.PhoneCode.length != 6) {
        this.$message.error('验证码不合法');
        return;
      }

      this.fullscreenLoading = true;
      let self = this;
      NetWork.Post(URLConfig.loginSecurity, {
        userAccount: this.UserName,
        password: this.UserPwd
      }, (res) => {
        if (res.data.items != null) {
          debugger
          GlobleClass.UserData = res.data.items;
          //登陆验证
          self.GetQuanXian(() => {
            self.$router.push({ name: 'main' })
          });
        } else {
          self.fullscreenLoading = false;
          self.$message.error(res.data.erMessage);
        }
      }, () => {
        self.fullscreenLoading = false;
        self.$message.error('登录失败');
      })
    },
    GetQuanXian: function (endBlock) {
      NetWork.Post(URLConfig.getAuthority, {
        userId: GlobleClass.UserData.userId,
        type: 'orderDeal'//处理
      }, (res) => {
        self.fullscreenLoading = false;
        if (res.data.items != null) {
          GlobleClass.UserData.isOrderDeal = res.data.items == 'true' ? true : false;
          // GlobleClass.UserData.isOrderDeal = false;
          endBlock();
        } else {
          self.$message.error(res.data.erMessage);
        }
      }, () => {
        self.fullscreenLoading = false;
        self.$message.error('获取权限失败');
      })
    }
  }
}
</script>

<style scoped>
.LoginBackView {
  justify-content: center;
}
.CenterView {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Title {
  color: rgba(98, 93, 93, 100);
  font-size: 18px;
  font-family: PingFangSC-semiBold;
  margin-bottom: 16px;
}
.Title_1 {
  color: rgba(98, 93, 93, 100);
  font-size: 14px;
  font-family: PingFangSC-semiBold;
}
.InputView {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.UserTitle {
  color: rgba(98, 93, 93, 100);
  font-size: 12px;
  font-family: PingFangSC-semiBold;
  width: 80px;
  margin-right: 16px;
}
.LoginButtonView {
  margin-top: 16px;
  width: 100px;
  height: 40px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
}
.YanZhengCode {
  width: 100%;
  display: flex;
  position: relative;
}
.CodeButton {
  /* flex: 1; */

  /* width: 50px;
  margin-left: 25px; */
  width: 80px;
  position: absolute;
  right: 0px;
  height: 100%;

  border: none;

  outline: none;
}
</style>