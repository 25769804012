<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='个人信息'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            姓名:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(userInFo.userName)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            工号:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(userInFo.idNumber)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            电话:
          </div>
          <div class="CellContent_Cell_Content">
            <label class="PhoneText"
                   @click="CallPhone(jianCeObject.GetValue(userInFo.telephone))">{{jianCeObject.GetValue(userInFo.telephone)}}</label>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            权限:
          </div>
          <div class="CellContent_Cell_Content">
            {{GetGangWeiText(userInFo.userType)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            岗位:
          </div>
          <div class="CellContent_Cell_Content">
            内容暂无
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            备注:
          </div>
          <div class="CellContent_Cell_Content">
            内容暂无
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            个人证书:
          </div>
          <div class="CellContent_Cell_Content">
            内容暂无
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            培训精力:
          </div>
          <div class="CellContent_Cell_Content">
            内容暂无
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      userInFo: {
        "userName": "-",
        "personName": "--",
        "gender": "--",
        "phoneNo": "--",
        "jobNo": "--",
        "certificateNo": '--',
        "author": '--',
        "post": '--',
        "remak": '--',
        "certificat": '--',
        "training": '--'
      },
      jianCeObject: ValueJianCe,
    }
  },
  mounted: function () {
    this.GetListData();
  },
  methods: {
    GetGangWeiText: function (userType) {
      switch (userType) {
        case 'SUPER_ADMIN':
          return '超级管理员'
          break;
        case 'TENANT':
          return '租户管理'
          break;
        case 'APP_ADMIN':
          return '应用管理员'
          break;
        case 'GENERAL':
          return '普通用户'
          break;

        default:
          return '暂无'
          break;
      }
    },
    CallPhone: function (phoneNumber) {
      if (phoneNumber != '--') {
        window.location.href = 'tel://' + phoneNumber
      }
    },
    //获取网络数据
    GetListData: function () {
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this;
      window.cao = GlobleClass
      NetWork.Post(URLConfig.getUserInfo, {
        userId: GlobleClass.UserData.userId
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.userInFo = res.data.items;
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    },
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
.PhoneText {
  color: cornflowerblue;
}
</style>