import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import MainView from '../views/Main.vue'

import GongDanCreate from '../views/GongDanPage/GongDanCreate.vue'
import NengHaoContent from '../views/NengHaoPage/NengHaoContent.vue'
import QuanXianPage from '../views/SetPage/QuanXianPage.vue'
import FangKeGuanLi from '../views/SetPage/FangKeGuanLi.vue'
import PeopleContent from '../views/SetPage/PeopleContent.vue'
import TongJiPage from '../views/HuanJingPage/TongJiPage.vue'

import WeiBaoPageMain from '../views/YingYongGuanLi/WeiBaoPage/WeiBaoPageMain.vue'
import WeiBaoPageCreate from '../views/YingYongGuanLi/WeiBaoPage/WeiBaoPageCreate.vue'

import XunJianPageMain from '../views/YingYongGuanLi/XunJianPage/XunJianPageMain.vue'
import XunJianPageCreate from '../views/YingYongGuanLi/XunJianPage/XunJianPageCreate.vue'

import WeiXiuPageMain from '../views/YingYongGuanLi/WeiXiuPage/WeiXiuPageMain.vue'
import WeiXiuPageCreate from '../views/YingYongGuanLi/WeiXiuPage/WeiXiuPageCreate.vue'

import ZhuangXiuPageMain from '../views/YingYongGuanLi/ZhuangXiuPage/ZhuangXiuPageMain.vue'
import ZhuangXiuPageCreate from '../views/YingYongGuanLi/ZhuangXiuPage/ZhuangXiuPageCreate.vue'

import ZhiShiPageMain from '../views/YingYongGuanLi/ZhiShiKuPage/ZhiShiPageMain.vue'
import ZhiShiPageCreate from '../views/YingYongGuanLi/ZhiShiKuPage/ZhiShiPageCreate.vue'
import ZhiShiPageConent from '../views/YingYongGuanLi/ZhiShiKuPage/ZhiShiPageConent.vue'

import QRCodePage from '../views/QRCodePage/QRCodePage.vue'

import XunGengPageMainXunGeng from '../views/YingYongGuanLi/XunGeng/XunGengPageMainXunGeng.vue'
import XunGengPageMainShenPi from '../views/YingYongGuanLi/XunGeng/XunGengPageMainShenPi.vue'
import XunGengPageCreate from '../views/YingYongGuanLi/XunGeng/XunGengPageCreate.vue'

import XiaoXiPageMain from '../views/XiaoXiPage/XiaoXiPageMain.vue'

import QRCode from '../views/PublicPage/QRCode/QRCode.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/main',
    name: 'main',
    component: MainView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/GongDanCreate',
    name: 'GongDanCreate',
    component: GongDanCreate
  },
  {
    path: '/NengHaoContent',
    name: 'NengHaoContent',
    component: NengHaoContent
  },
  {
    path: '/QuanXianPage',
    name: 'QuanXianPage',
    component: QuanXianPage
  },
  {
    path: '/FangKeGuanLi',
    name: 'FangKeGuanLi',
    component: FangKeGuanLi
  },
  {
    path: '/PeopleContent',
    name: 'PeopleContent',
    component: PeopleContent
  },
  {
    path: '/TongJiPage',
    name: 'TongJiPage',
    component: TongJiPage
  },
  {
    path: '/WeiBaoPageMain',
    name: 'WeiBaoPageMain',
    component: WeiBaoPageMain,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/WeiBaoPageCreate',
    name: 'WeiBaoPageCreate',
    component: WeiBaoPageCreate
  },
  {
    path: '/XunJianPageMain',
    name: 'XunJianPageMain',
    component: XunJianPageMain,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/XunJianPageCreate',
    name: 'XunJianPageCreate',
    component: XunJianPageCreate
  },
  {
    path: '/WeiXiuPageMain',
    name: 'WeiXiuPageMain',
    component: WeiXiuPageMain,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/WeiXiuPageCreate',
    name: 'WeiXiuPageCreate',
    component: WeiXiuPageCreate
  },
  {
    path: '/ZhuangXiuPageMain',
    name: 'ZhuangXiuPageMain',
    component: ZhuangXiuPageMain,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ZhuangXiuPageCreate',
    name: 'ZhuangXiuPageCreate',
    component: ZhuangXiuPageCreate
  },
  {
    path: '/ZhiShiPageMain',
    name: 'ZhiShiPageMain',
    component: ZhiShiPageMain,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ZhiShiPageCreate',
    name: 'ZhiShiPageCreate',
    component: ZhiShiPageCreate
  },
  {
    path: '/ZhiShiPageConent',
    name: 'ZhiShiPageConent',
    component: ZhiShiPageConent
  },
  {
    path: '/QRCodePage',
    name: 'QRCodePage',
    component: QRCodePage
  },
  {
    path: '/XunGengPageMainXunGeng',
    name: 'XunGengPageMainXunGeng',
    component: XunGengPageMainXunGeng,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/XunGengPageMainShenPi',
    name: 'XunGengPageMainShenPi',
    component: XunGengPageMainShenPi
  },
  {
    path: '/XunGengPageCreate',
    name: 'XunGengPageCreate',
    component: XunGengPageCreate,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/XiaoXiPageMain',
    name: 'XiaoXiPageMain',
    component: XiaoXiPageMain
  },
  {
    path: '/QRCode',
    name: 'QRCode',
    component: QRCode
  },  
]

const router = new VueRouter({
  routes
})

export default router
