<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <PhotoPage v-if="isOpenPaiZhao"
               :ShowState="isOpenPaiZhao"
               :ShowImageData="ShowImageData"
               :IsShowClickImage="IsShowImage"
               @EndImageBlock="EndImageBlock"
               @ChoseImageBlock="ChoseImageBlock"
               style="position:absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;width:100%;height:100%"></PhotoPage>
  </div>
</template>

<script>

require('./views/PageConfig')
require('./components/ToolClass/DataConfig')
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

import PhotoPage from '@/views/PublicPage/PhotoPage/PhotoPage'

export default {
  components: {
    PhotoPage
  },
  data: function () {
    return {
      nowUUID: '',//当前拍照的唯一编号
      isOpenPaiZhao: false,
      IsShowImage: false,
      ShowImageData: ''
    }
  },
  mounted: function () {
    this.InitEvent();
  },
  methods: {
    //注册事件
    InitEvent: function () {
      let self = this;
      this.$EventBus.$off('PaiZhaoMessage');
      this.$EventBus.$on('PaiZhaoMessage', (UUID) => {
        self.nowUUID = UUID;
        this.IsShowImage = false;
        this.isOpenPaiZhao = true
      })

      this.$EventBus.$off('ShowImage');
      this.$EventBus.$on('ShowImage', (imageData) => {
        this.ShowImageData = imageData;
        this.isOpenPaiZhao = true;
        this.IsShowImage = true;
      })
    },
    //拍照结束
    ChoseImageBlock: function () {
      this.IsShowImage = false;
      this.isOpenPaiZhao = false;
    },
    EndImageBlock: function (imageData) {
      this.ChoseImageBlock();
      this.$EventBus.$emit('ShowImageEnd', this.nowUUID, imageData);
    }
  }
}
</script>

<style>
/** 所有非透明部分的跟容器 */
.RootPanelView {
  pointer-events: auto;
}
/** 所有内容滚动容器 */
.PanelScrollView {
  overflow: auto;
}
.PanelScrollView::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.PanelScrollView::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.PanelScrollView::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: #ededed;
}
.MenuBGView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.selectBox {
  background: rgba(0, 145, 255, 0.1);
  border: 2px solid #0091ff;
  box-sizing: border-box;
  position: fixed;
}
.BacePageView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.PageContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0px;
}
.CellContent {
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CellBorder {
  margin-left: 16px;
  background-color: #bebebe;
  border-top: 1px solid rgba(187, 187, 187, 100);
}
.Cell_Title {
  margin-left: 16px;
  background-color: rgba(255, 255, 255, 0);
  color: #101010;
  font-size: 12px;
  text-align: left;
  font-family: Microsoft Yahei;
  flex: 1;
  height: 48px;
  overflow: hidden;
}
.Cell_Value {
  background-color: rgba(255, 255, 255, 0);
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.Cell_JianTou {
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
</style>
