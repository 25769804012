<template>
  <div class="BacePageView">
    <div class="PageView">
      <component :is="ComName"></component>
    </div>
    <div class="TabBarView">
      <div class="TableView"
           @click="TabClick('GongDanPage')">
        <div class="TextView">
          <label class="TabTitle"
                 :class="{ChoseTitle:ComName==='GongDanPage'}">工单</label>
          <label v-if="isChuLi" class="Number">{{OrderNumber}}</label>
        </div>
      </div>
      <div class="TableView"
           @click="TabClick('HuanJingPage')">
        <label class="TabTitle"
               :class="{ChoseTitle:ComName==='HuanJingPage'}">环境</label>
      </div>
      <div class="TableView"
           @click="TabClick('YingYongMain')">
        <label class="TabTitle"
               :class="{ChoseTitle:ComName==='YingYongMain'}">应用</label>
      </div>
      <div class="TableView"
           @click="TabClick('SetPage')">
        <label class="TabTitle"
               :class="{ChoseTitle:ComName==='SetPage'}">设置</label>
      </div>
    </div>
  </div>
</template>

<script>
import GongDanPage from '@/views/GongDanPage/GongDanPage'
import HuanJingPage from '@/views/HuanJingPage/HuanJingPage'
import NengHaoPage from '@/views/NengHaoPage/NengHaoPage'
import YingYongMain from '@/views/YingYongGuanLi/YingYongMain'
import SetPage from '@/views/SetPage/SetPage'

import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    GongDanPage,
    HuanJingPage,
    NengHaoPage,
    SetPage,
    YingYongMain
  },
  data: function () {
    return {
      ComName: "GongDanPage",//当前的功能模块
      OrderNumber: 0,//所有未处理工单数量    
      isChuLi:GlobleClass.UserData.isOrderDeal
    }
  },
  mounted: function () {
    let self = this;
    this.$EventBus.$off('ChangeOrderNumber');
    this.$EventBus.$on('ChangeOrderNumber', (number) => {
      self.OrderNumber = number;
    })

    this.$EventBus.$off('GetNowOrderNumber');
    this.$EventBus.$on('GetNowOrderNumber', () => {
      self.GetOrderNumber();
    })

    this.GetOrderNumber();
  },
  methods: {
    //获取工单数量
    GetOrderNumber: function () {
      let self = this;
      NetWork.Post(URLConfig.getMessageCount, null, (data) => {
        if (data.data.err != null && data.data.err == 0) {
          GlobleClass.GongDanNumber = data.data.items;
          self.$EventBus.$emit('ReloadOrderNumber');
        }
      }, () => {

      })
    },
    //Tab点击事件
    TabClick: function (name) {
      this.ComName = name;
    }
  }
}
</script>

<style scoped>
.PageView {
  display: flex;
  flex: 1;
  width: 100%;
  overflow-x: hidden;
}
.TabBarView {
  width: 100%;
  height: 50px;
  line-height: 20px;
  text-align: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(187, 187, 187, 100);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.TableView {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.TabTitle {
  position: relative;
  color: rgba(129, 131, 143, 100);
  font-size: 12px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
}
.TextView {
  display: flex;
  flex-direction: row;
}
.Number {
  min-width: 14px;
  height: 14px;
  border-radius: 7px 7px 7px 7px;
  background-color: rgba(229, 77, 66, 100);
  text-align: center;
  color: rgba(255, 255, 255, 100);
  font-size: 6px;
  text-align: center;
  font-family: Arial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ChoseTitle {
  color: rgba(52, 204, 95, 100);
}
</style>