export default class ValueJianCe{
  /**
   *默认值
   *
   * @static
   * @memberof ValueJianCe
   */
  static defaultValue='--'

  static GetValue(value){
    try {
      if (value==='' || value===null) {
        return this.defaultValue;
      }else{
        return value;
      }
    } catch (error) {
      return this.defaultValue;
    }
  }
}