<template>
  <div class="NavigationView">
    <div class="TopView">

    </div>
    <div class="Bottom">
      <div class="GoBack">
        <div class="GoBack"
             v-if="IsBack"
             @click="GoBack">
          <img style="width:22px;height:22px"
               :src="backSrc" />
        </div>
        <div class="GoBack"
             v-if="isShowOrderNumber"
             @click="GoToXiaoXi">
          <div class="XiaoXiIconView">
            {{orderAllNumber}}
          </div>
        </div>

      </div>
      <div class="CenterView">
        <label>{{Title}}</label>
      </div>
      <div class="RightView">
        <div class="RightView"
             v-if="IsCode"
             @click="GoToQRPage">
          <img style="width:22px;height:22px"
               :src="codeSrc" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import GlobleClass from '@/components/ToolClass/GlobleClass';
export default {
  props: {
    IsBack: {
      type: Boolean,
      default: true
    },
    IsXiaoXi: {
      type: Boolean,
      default: false
    },
    IsCode: {
      type: Boolean,
      default: true
    },
    Title: {
      type: String,
      default: '实验室APP'
    },
    BackFunction: {
      type: Function
    }
  },
  data: function () {
    return {
      backSrc: window.RootPath + 'Images/GoBack.png',
      codeSrc: window.RootPath + 'Images/CodeNumber.png',
      orderAllNumber: '',
      isShowOrderNumber: false
    }
  },
  mounted: function () {
    this.InitEvent();
    this.UpdateOrderNumber();
  },
  methods: {
    //注册事件
    InitEvent: function () {
      let self = this;
      this.$EventBus.$off('ReloadOrderNumber');
      this.$EventBus.$on('ReloadOrderNumber', () => {
        debugger
        self.UpdateOrderNumber();
      });
    },
    UpdateOrderNumber: function () {
      if (this.IsXiaoXi == true) {
        //获取当前未处理工单数量
        this.orderAllNumber = GlobleClass.GongDanNumber;
        if (this.orderAllNumber == 0) {
          this.isShowOrderNumber = false;
        } else {
          this.isShowOrderNumber = true;
          if (this.orderAllNumber >= 100) {
            this.orderAllNumber = '99+'
          }
        }
      } else {
        this.isShowOrderNumber = false;
      }
    },
    GoBack: function () {
      this.$router.back();
      this.$route.meta.keepAlive = false;
    },
    GoToXiaoXi: function () {
      this.$router.push({ name: 'XiaoXiPageMain' })
    },
    GoToQRPage: function () {
      this.$router.push({ name: 'QRCodePage' })
    }
  }
}
</script>

<style scoped>
.NavigationView {
  display: flex;
  flex-direction: column;
  background-color: #1d8ae7;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
}
.TopView {
  width: 100%;
  height: 0px;
}
.Bottom {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.GoBack {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CenterView {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-family: Arial;
}
.RightView {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.XiaoXiIconView {
  background-color: rgb(199, 105, 105);
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  min-width: 30px;
}
</style>