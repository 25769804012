import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


import 'lib-flexible/flexible'

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
console.log('版本号:'+1)
//修改bug版本
