<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='装修'></NavigationView>
    <div class="PageContent">
      <div class="PaiXuView">
        <div>
          <el-date-picker v-model="choseDate"
                          type="month"
                          placeholder="选择日期"
                          value-format="yyyy-MM"
                          @change="dateChange">
          </el-date-picker>
        </div>
        <div class="PaiXuView_Right">
          <van-dropdown-menu>
            <van-dropdown-item v-model="sortValue"
                               :options="option1"
                               @change="dropdownChange" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="ListView">

        <van-pull-refresh v-model="refreshing"
                          @refresh="onRefresh">
          <van-list v-model:loading="loading"
                    :finished="finished"
                    @load="onLoad">

            <el-collapse accordion
                         ref="MyCollapse">
              <el-collapse-item v-for="(item,index) in list"
                                :key="index">
                <template slot="title">
                  <div class="CellView">
                    <div class="CellContent">
                      <div v-if="isChuLi"
                           :class="{StateView:(item.isDeal=='0'),StateView_1:(item.isDeal!=='0')}">

                      </div>
                      <div v-if="!isChuLi"
                           :class="{StateView:(item.isDeal!='2'),StateView_1:(item.isDeal=='2')}">

                      </div>
                      <div class="Cell_Title">
                        {{jianCeObject.GetValue(item.decorationSpace)}}
                      </div>
                      <div class="Cell_Value">
                        {{jianCeObject.GetValue(item.createTime)}}
                      </div>

                    </div>

                  </div>
                </template>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    装修空间:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.decorationSpace)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    装修单位:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.decorationOrg)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    检查时间:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.decorationTime)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    单元面积:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.decorationArea)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    施工人数:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.decorationCount)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    动用明火:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.isFire)==1?"是":"否"}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    装修备注:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.remark)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    图片:
                  </div>
                  <div v-if="item.fileId==null"
                       class="CellContent_Cell_Content">
                    暂无图片
                  </div>
                  <div v-if="item.fileId!=null"
                       class="CellContent_Cell_Content">
                    <div v-if="item.imageData==''"
                         style="width:50px;height:50px;border: 1px solid rgba(187, 187, 187, 0.2);display: flex;flex-direction: row;align-items: center;justify-content: center;font-size: 6px;">
                      加载中
                    </div>
                    <img :src="item.imageData"
                         v-if="item.imageData!=''"
                         style="width:50px;height:50px"
                         @click="ShowBigImage(item)" />
                  </div>
                </div>
                <div v-if="isChuLi && item.isDeal=='0'"
                     class="CellContent_Cell">
                  <input type="button"
                         value="处理"
                         class="ChuLiButtonView"
                         @click="ChuLiOrder(item,'1')" />
                </div>
                <div v-if="!isChuLi && item.isDeal!='2'"
                     class="CellContent_Cell">
                  <input type="button"
                         value="审核"
                         class="ChuLiButtonView"
                         @click="ChuLiOrder(item,'2')" />
                </div>
              </el-collapse-item>
            </el-collapse>

          </van-list>
        </van-pull-refresh>

      </div>
      <div v-if="isChuLi"
           class="AddGongDan"
           @click="CreateGongDan">
        <img style="width:20px;height:20px"
             :src="addSrc" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      addSrc: window.RootPath + 'Images/Add.png',
      refreshing: false,
      loading: false,
      finished: true,
      list: [
      ],//列表数据源
      jianCeObject: ValueJianCe,
      choseDate: new Date().Format('yyyy-MM'),
      sortValue: '1F',
      option1: [
        { text: 'F1', value: '1F' },
        { text: 'F2', value: '2F' },
        { text: 'F3', value: '3F' },
        { text: 'F3', value: '4F' },
        { text: 'F3', value: '5F' },
        { text: 'F3', value: '6F' },
        { text: 'F3', value: '7F' }
      ],
      isChuLi: GlobleClass.UserData.isOrderDeal
    }
  },
  mounted: function () {
    this.InitEvent();
    //获取网络数据
    this.GetListData();
  },
  methods: {
    //展示大图
    ShowBigImage: function (item) {
      if (item.imageData != null && item.imageData != '') {
        this.$EventBus.$emit('ShowImage', item.imageData);
      }
    },
    //注册事件
    InitEvent: function () {
      let self = this;
      this.$EventBus.$off('ReloadData-ZhuangXiu');
      this.$EventBus.$on('ReloadData-ZhuangXiu', () => {
        self.GetListData();
      });
    },
    //获取网络数据
    GetListData: function () {
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this; debugger
      NetWork.Post(URLConfig.getDecorationList, {
        createTime: this.choseDate,
        decorationFlood: this.sortValue
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          for (let index = 0; index < res.data.items.length; index++) {
            const element = res.data.items[index];
            element.imageData = '';
          }
          self.list = res.data.items;
          self.GetAllImageData();
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    },
    GetAllImageData: function () {
      debugger
      let self = this;
      for (let index = 0; index < this.list.length; index++) {
        const item = this.list[index];
        if (item.fileId != null) {
          NetWork.Post(URLConfig.getFile, {
            uploadFileId: item.fileId
          }, (res) => {
            if (res.data.err !== null && res.data.err == 0) {
              item.imageData = res.data.items.fileBase;
            }
          }, () => {

          })
        }

      }
    },
    //排序规则变更
    dropdownChange: function (value) {
      this.GetListData();
    },
    //重新获取时间
    dateChange: function (value) {
      this.GetListData();
    },
    //处理工单
    ChuLiOrder: function (item, type) {
      let loading = GlobleClass.GetLoadingParam();
      let self = this;
      NetWork.Post(URLConfig.updateDecoration, {
        decorationId: item.decorationId,
        dealStaff: GlobleClass.UserData.userId,
        isDeal: type
      }, (data) => {
        loading.close();
        if (data.data.err !== null && data.data.err == 0) {//成功
          item.isDeal = type;
          self.$message({
            message: '处理成功',
            type: 'success'
          });
          self.$refs.MyCollapse.activeNames = [];
          self.$EventBus.$emit('GetNowOrderNumber');
        } else {
          self.$message.error('处理失败');
        }
      }, () => {
        loading.close();
      })
    },
    //上拉加载
    onLoad: function () {
      this.loading = false;
      this.finished = true;
    },
    //下拉刷新
    onRefresh: function () {
      this.refreshing = false;
    },
    //创建工单
    CreateGongDan: function () {
      this.$router.push({ name: 'ZhuangXiuPageCreate' })
    }
  }
}
</script>

<style scoped>
.PaiXuView {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(187, 187, 187, 100);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
}
.ListView {
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-basis: 0;
}
.AddGongDan {
  position: absolute;
  right: 16px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
}
.CellView {
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 100%;
}
.StateView {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #e54d42;
}
.StateView_1 {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #636363;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border-top: 1px solid rgba(187, 187, 187, 0.2);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 46px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
  position: absolute;
  right: 16px;
}
.PaiXuView_Right {
  flex: 1;
}
</style>