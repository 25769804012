<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    :Title='name'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            文件名称:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(this.$route.params.item.knowledgeName)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            创建时间:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(this.$route.params.item.createTime)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            创建人员:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(this.$route.params.item.createStaff)}}
          </div>
        </div>
        <div class="CellContent_Cell_FileContent">
          <div class="CellContent_Cell_Title">
            文件内容:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(this.$route.params.item.knowledgeInfo)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'

import ValueJianCe from '@/components/ToolClass/ValueJianCe'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      name: this.$route.params.item.knowledgeName,//文件名称
      OrderModel: {
        wenJianMingCheng: "",
        chuangJianShiJian: "",
        chuangJianRenYuan: "",
        wenJianNeiRong: ""
      },
      jianCeObject: ValueJianCe
    }
  },
  mounted: function () {

  },
  methods: {
    //提交工单
    InputOrder: function () {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_FileContent {
  display: flex;
  flex-direction: row;
  align-items: center;  
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
</style>