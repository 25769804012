<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='新建知识库'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            文件名称:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.wenJianMingCheng"
                      placeholder="请输入文件名称"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            创建时间:
          </div>
          <div class="CellContent_Cell_Content">
            <el-date-picker v-model="OrderModel.chuangJianShiJian"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            文件内容:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.wenJianNeiRong"
                      placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <div class="ButtonView">
        <el-button type="primary"
                   class="ChuLiButtonView"
                   @click="InputOrder">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import GlobleClass from '@/components/ToolClass/GlobleClass'
export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      OrderModel: {
        wenJianMingCheng: "",
        chuangJianShiJian: new Date().Format('yyyy-MM-dd'),
        chuangJianRenYuan: "",
        wenJianNeiRong: ""
      }
    }
  },
  mounted: function () {

  },
  methods: {
    //提交工单
    InputOrder: function () {
      //检测数据
      if (this.OrderModel.wenJianMingCheng == '') {
        this.$message.error('请输入文件名称');
        return
      }
      if (this.OrderModel.wenJianNeiRong == '') {
        this.$message.error('请输入内容');
        return
      }

      //打开Loading
      let param = {
        knowledgeName: this.OrderModel.wenJianMingCheng,
        createTime: this.OrderModel.chuangJianShiJian,
        createStaff: GlobleClass.UserData.userId,
        knowledgeInfo: this.OrderModel.wenJianNeiRong
      };
      let loading = GlobleClass.GetLoadingParam();
      let self = this; debugger
      NetWork.Post(URLConfig.addKnowledge, param, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.$message({
            message: '新建成功',
            type: 'success'
          });
          this.$router.go(-1);
          self.$EventBus.$emit('ReloadData-ZhiShiKu');
        } else {
          self.$message.error('新建失败');
        }
      }, () => {
        loading.close();
        self.$message.error('新建失败');
      })
    }
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
</style>