<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='新建装修'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            装修楼层:
          </div>
          <div class="CellContent_Cell_Content">
            <el-select v-model="OrderModel.zhuangXiuLouCeng"
                       placeholder="请选择">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            装修空间:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.zhuangXiuKongJian"
                      placeholder="请输入空间信息"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            装修单位:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.zhuangXiuDanWei"
                      placeholder="请输入单位"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            检查时间:
          </div>
          <div class="CellContent_Cell_Content">
            <el-date-picker v-model="OrderModel.jianChaShiJian"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            单元面积:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.danYuanMianJi"
                      placeholder="请输入面积"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            施工人数:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.shiGongRenShu"
                      placeholder="请输入施工人数"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            动用明火:
          </div>
          <div class="CellContent_Cell_Content">
            <el-select v-model="OrderModel.dongYongMingHuo"
                       placeholder="请选择">
              <el-option v-for="item in options_1"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            拍照:
          </div>
          <div class="CellContent_Cell_Content">
            <ShowImageView @imageDataChange="imageDataChange"></ShowImageView>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            装修备注:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.zhuangXiuBeiZhu"
                      placeholder="请输入备注信息"></el-input>
          </div>
        </div>
      </div>
      <div class="ButtonView">
        <el-button type="primary"
                   class="ChuLiButtonView"
                   @click="InputOrder">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import GlobleClass from '@/components/ToolClass/GlobleClass'
import ShowImageView from '@/components/ToolView/ShowImageView/ShowImageView'
export default {
  components: {
    NavigationView,
    ShowImageView
  },
  data: function () {
    return {
      options: [{
        value: '1F',
        label: 'F1'
      }, {
        value: '2F',
        label: 'F2'
      }, {
        value: '3F',
        label: 'F3'
      }, {
        value: '4F',
        label: 'F4'
      }, {
        value: '5F',
        label: 'F5'
      }, {
        value: '6F',
        label: 'F6'
      }, {
        value: '7F',
        label: 'F7'
      }],
      options_1: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      OrderModel: {
        zhuangXiuLouCeng: "1F",
        zhuangXiuKongJian: "",
        zhuangXiuDanWei: "",
        jianChaShiJian: new Date().Format('yyyy-MM-dd'),
        danYuanMianJi: "",
        shiGongRenShu: '',
        dongYongMingHuo: 1,
        zhuangXiuBeiZhu: "",
      },
      imageData: ""
    }
  },
  mounted: function () {

  },
  methods: {
    imageDataChange: function (imageData) {
      this.imageData = imageData;
    },
    //提交工单
    InputOrder: function () {
      //检测数据
      if (this.OrderModel.zhuangXiuKongJian == '') {
        this.$message.error('请输入空间信息');
        return
      }
      if (this.OrderModel.zhuangXiuDanWei == '') {
        this.$message.error('请输入单位信息');
        return
      }
      if (this.OrderModel.danYuanMianJi == '') {
        this.$message.error('请输入面积信息');
        return
      }
      if (this.OrderModel.shiGongRenShu == '') {
        this.$message.error('请输入施工人数');
        return
      }
      if (this.OrderModel.zhuangXiuBeiZhu == '') {
        this.$message.error('请输入备注');
        return
      }

      //打开Loading
      let param = {
        decorationFlood: this.OrderModel.zhuangXiuLouCeng,
        decorationSpace: this.OrderModel.zhuangXiuKongJian,
        decorationOrg: this.OrderModel.zhuangXiuDanWei,
        decorationTime: this.OrderModel.jianChaShiJian,
        decorationArea: this.OrderModel.danYuanMianJi,
        decorationCount: this.OrderModel.shiGongRenShu,
        isFire: this.OrderModel.dongYongMingHuo,
        remark: this.OrderModel.zhuangXiuBeiZhu,
        createTime: new Date().Format('yyyy-MM-dd'),
        createStaff: GlobleClass.UserData.userName
      };
      let loading = GlobleClass.GetLoadingParam();
      let self = this; debugger

      if (this.imageData != null && this.imageData != '') {
        //上传图片
        NetWork.UpdateImage(URLConfig.uploadFile, this.imageData, 'fileName', (res) => {
          if (res.data.err !== null && res.data.err == 0) {
            param.fileId = res.data.items.uploadFileId;
            self.InputOrder_1(param, loading);
          } else {
            self.$message.error('图片上传失败');
          }
        }, () => {
          self.$message.error('图片上传失败');
        })
      } else {
        this.InputOrder_1(param, loading);
      }
    },
    InputOrder_1: function (param, loading) {
      let self = this;
      NetWork.Post(URLConfig.addDecoration, param, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.$message({
            message: '新建成功',
            type: 'success'
          });
          this.$router.go(-1);
          self.$EventBus.$emit('ReloadData-ZhuangXiu');
        } else {
          self.$message.error('新建失败');
        }
      }, () => {
        loading.close();
        self.$message.error('新建失败');
      })
    }
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
  overflow: auto;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
</style>