<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='巡更'></NavigationView>
    <div class="PageContent">
      <div class="ListView">

        <van-pull-refresh v-model="refreshing"
                          @refresh="onRefresh">
          <van-list v-model:loading="loading"
                    :finished="finished"
                    @load="onLoad">

            <el-collapse accordion
                         ref="MyCollapse">
              <el-collapse-item v-for="(item,index) in list"
                                :key="index">
                <template slot="title">
                  <div class="CellView">
                    <div class="CellContent">
                      <div class="Cell_Title">
                        {{jianCeObject.GetValue(item.deviceName)}}
                      </div>
                      <div class="Cell_Title">
                        {{'状态:'+jianCeObject.GetValue(item.statusText)}}
                      </div>
                      <div class="Cell_Value">
                        <el-button type=""
                                   @click="SaoQRClick(item)">扫码</el-button>
                      </div>

                    </div>

                  </div>
                </template>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    打卡时间:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.createTime)}}
                  </div>
                </div>
                <div class="CellContent_Cell">
                  <div class="CellContent_Cell_Title">
                    巡检记录:
                  </div>
                  <div class="CellContent_Cell_Content">
                    {{jianCeObject.GetValue(item.logStatus)}}
                  </div>
                </div>

              </el-collapse-item>
            </el-collapse>

          </van-list>
        </van-pull-refresh>

      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'

import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      active: 0,//楼层数据选择下标
      fullscreenLoading: false,//是否出现Loading页面
      list: [],
      refreshing: false,
      loading: false,
      finished: true,
      jianCeObject: ValueJianCe,
      batch:''
    }
  },
  mounted: function () {
    // if (this.$route.params.batch!=null) {
      if (this.$route.query.batch!=null) {
      this.batch=this.$route.query.batch;
    }
    this.InitEvent();
    //默认加载环境列表数据
    this.GetDataList();
  },
  methods: {
    //注册事件
    InitEvent: function () {
      let self = this;
      this.$EventBus.$off('ReloadData-XunGengQR');
      this.$EventBus.$on('ReloadData-XunGengQR', (newItem) => {
        self.SaoYiSaoClick(newItem);
      });
    },
    SaoYiSaoClick (newItem) {debugger   
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this;
      let time=new Date().Format('yyyy-MM-dd hh:ss:mm');
      NetWork.Post(URLConfig.patroLogUpdate, {
        electronicPatrolLogId: newItem.electronicPatrolLogId,
        deviceCode: newItem.deviceCode
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          self.$message({
            message: '扫码成功',
            type: 'success'
          });
          for (let index = 0; index < self.list.length; index++) {
            const element = self.list[index];
            if (element.electronicPatrolLogId == newItem.electronicPatrolLogId) {
              element.status = 2;
              element.statusText = '完成';
              element.createTime=time
              break
            }
          }
          if (self.$refs.MyCollapse!=null) {
            self.$refs.MyCollapse.activeNames = []; 
          }
        } else {
          self.$message.error(res.data.erMessage);
        }
      }, () => {
        loading.close();
        self.$message.error('扫码失败');
      })
    },
    //获取环境数据
    GetDataList: function () {
      debugger
      //打开Loading
      let loading = GlobleClass.GetLoadingParam();
      let self = this;
      NetWork.Post(URLConfig.getPatroLoglList, {
        batch: this.batch
      }, (res) => {
        loading.close();
        if (res.data.err !== null && res.data.err == 0) {
          for (let index = 0; index < res.data.items.length; index++) {
            const element = res.data.items[index];
            if (element.status != null && element.status == 2) {
              element.statusText = '完成';
            } else {
              element.statusText = '未完成';
            }
          }
          self.list = res.data.items;
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    },
    //上拉加载
    onLoad: function () {
      this.loading = false;
      this.finished = true;
    },
    //下拉刷新
    onRefresh: function () {
      this.refreshing = false;
    },
    //扫码
    SaoQRClick: function (item) {
      this.$router.push({
        name: 'QRCode', params: {
          item: item
        }
      })
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
  overflow: auto;
}
.HuanJingContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(187, 187, 187, 100);
}
.TongJiView {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  font-family: Arial;
}
.ListView {
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-basis: 0;
}
.CellView {
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 100%;
}
.StateView {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #e54d42;
}
.StateView_1 {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #636363;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border-top: 1px solid rgba(187, 187, 187, 0.2);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 46px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
  position: absolute;
  right: 16px;
}
.PaiXuView_Right {
  flex: 1;
}
</style>