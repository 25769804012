import { render, staticRenderFns } from "./XunGengPageCreate.vue?vue&type=template&id=11f67360&scoped=true&"
import script from "./XunGengPageCreate.vue?vue&type=script&lang=js&"
export * from "./XunGengPageCreate.vue?vue&type=script&lang=js&"
import style0 from "./XunGengPageCreate.vue?vue&type=style&index=0&id=11f67360&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11f67360",
  null
  
)

export default component.exports