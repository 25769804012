import { Loading } from 'element-ui';
import NetWork from './NetWork';
import URLConfig from './URLConfig';

export default class GlobleClass {
  static UserData = {
    "appCode": null,
    "token": null,
    "ageSize": null,
    "toPage": null,
    "userId": '2',
    "userName": "张三",
    "password": null,
    "userAccount": "",
    "telephone": "",
    "sex": "",
    "idNumber": null,
    "userType": "",
    "status": null,
    "isDeleted": null,
    "email": null,
    isOrderDeal: true
  }

  static GongDanNumber = 0;

  static allSystem = null;

  static allDengJi = null;

  /**
   * 获取Loading界面
   * @param {*} text 
   * @returns 
   */
  static GetLoadingParam (text) {
    return Loading.service({
      // lock: true,
      // text: text,
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
    })
  }
  /**
   * 获取所有的系统
   * @returns 
   */
  static GetAllSystem (isHaveAll = true, endBock) {
    let self = this;
    if (this.allSystem == null) {
      NetWork.Post(URLConfig.getOrderSys, {

      }, (data) => {
        if (data.data.err != null && data.data.err == 0) {
          self.allSystem = [];
          let items = data.data.items;
          for (let index = 0; index < items.length; index++) {
            const element = items[index];
            self.allSystem.push({
              value: element.key,
              label: element.value,
              text: element.value
            });
          }
          if (isHaveAll) {
            let newSystem = [...self.allSystem];
            newSystem.splice(0, 0, {
              value: '',
              label: '全部',
              text: '全部'
            });
            endBock(newSystem);
          } else {
            endBock(self.allSystem);
          }
        } else {
          self.$message.error('获取对象系统失败');
        }
      }, () => {
        self.$message.error('获取对象系统失败');
      })
    } else {
      if (isHaveAll) {
        let newSystem = [...this.allSystem];
        newSystem.splice(0, 0, {
          value: '',
          label: '全部',
          text: '全部'
        });
        endBock(newSystem);
      } else {
        endBock(this.allSystem);
      }
    }
  }

  /**
   * 获取所有等级
   */
  static GetDengJi (endBock) {
    debugger
    let self = this;
    if (this.allDengJi == null) {
      NetWork.Post(URLConfig.getOrderLevel, {

      }, (data) => {
        if (data.data.err != null && data.data.err == 0) {
          self.allDengJi = [];
          let items = data.data.items;
          for (let index = 0; index < items.length; index++) {
            const element = items[index];
            self.allDengJi.push({
              value: element.key,
              label: element.value,
              text: element.value
            });
          }
          endBock(self.allDengJi);
        } else {
          self.$message.error('获取等级失败');
        }
      }, () => {
        self.$message.error('获取等级失败');
      })
    } else {
      endBock(this.allDengJi);
    }
  }

  /**
   * 获取系统维修设备列表
   * @param {*} key 
   * @param {*} endBlock 
   */
  static GetWeiXiuEqList (key, endBlock) {
    let self = this;
    if (this.allDengJi == null) {
      NetWork.Post(URLConfig.getDevice, {
        pamra: key
      }, (data) => {
        if (data.data.err != null && data.data.err == 0) {
          let allDengJi = [];
          let items = data.data.items;
          for (let index = 0; index < items.length; index++) {
            const element = items[index];
            allDengJi.push({
              value: element.key,
              label: element.value,
              text: element.value
            });
          }
          endBlock(allDengJi);
        } else {
          self.$message.error('获取设备列表失败');
        }
      }, () => {
        self.$message.error('获取设备列表失败');
      })
    } else {
      endBock(this.allDengJi);
    }
  }

  static base64ToFile (dataurl, fileName) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], { type: mime });

    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

}