<template>
  <div class="BacePageView">
    <NavigationView :IsBack="false"
                    :IsCode="false"
                    Title='环境'></NavigationView>
    <div class="TongJiView"
         @click="GoToTongJiPage">
      统计
    </div>
    <div class="PageContent">
      <van-tabs v-model:active="active"
                @change="onClickTab">
        <van-tab v-for="item in floorList"
                 :title="item.dictValue">
        </van-tab>
      </van-tabs>
      <div class="HuanJingContent">
        <el-table :data="tableData"
                  style="width: 100%"
                  :default-sort="{prop: 'date', order: 'descending'}"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  element-loading-text="拼命加载中"
                  element-loading-background="rgba(0, 0, 0, 0.2)">
          <el-table-column prop="name"
                           label="空间名称">
          </el-table-column>
          <el-table-column prop="wenDu"
                           label="温度(℃)"
                           sortable
                           width="105">
          </el-table-column>
          <el-table-column prop="shiDu"
                           label="湿度(%)"
                           sortable
                           width="105">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'

import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      active: 0,//楼层数据选择下标
      fullscreenLoading: false,//是否出现Loading页面
      tableData: [{
        name: '样品保存实验室',
        wenDu: '12.0',
        shiDu: '12.0'
      }, {
        name: '样品保存实验室',
        wenDu: '12.0',
        shiDu: '12.0'
      }, {
        name: '样品保存实验室',
        wenDu: '12.0',
        shiDu: '12.0'
      }, {
        name: '样品保存实验室',
        wenDu: '12.0',
        shiDu: '12.0'
      }],
      floorList: []
    }
  },
  mounted: function () {
    //默认加载环境列表数据
    this.GetFloorList();
    this.GetDataList();
  },
  methods: {
    //获取楼层列表
    GetFloorList: function () {
      this.fullscreenLoading = true;
      let self = this;
      NetWork.Post(URLConfig.getDictList, {
        tableName: 'flood'
      }, (res) => {
        self.fullscreenLoading = false;
        if (res.data.err !== null && res.data.err == 0) {
          self.floorList = res.data.items;
          let newFloorList=[];
          for (let index = 0; index < self.floorList.length; index++) {
            const element = self.floorList[index];
            if (element.dictValue!='2F') {
              newFloorList.push(element);
            }
          }
          self.floorList=newFloorList;
          self.GetDataList();
        } else {
          self.$message.error('获取楼层失败');
        }
      }, () => {
        self.fullscreenLoading = false;
        self.$message.error('获取楼层失败');
      })
    },
    //获取环境数据
    GetDataList: function () {
      this.fullscreenLoading = true;
      let self = this;
      NetWork.Post(URLConfig.getEnvironmentList, {
        flood: this.floorList[this.active].dictValue
      }, (res) => {
        self.fullscreenLoading = false;
        if (res.data.err !== null && res.data.err == 0) {
          self.createData(res.data.items);
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        self.fullscreenLoading = false;
        self.$message.error('加载失败');
      })
    },
    //初始化数据源
    createData: function (items) {
      let dataList = [];
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        let dataObject = {
          name: ValueJianCe.GetValue(item.model),
          wenDu: ValueJianCe.GetValue(item.temperature),
          shiDu: ValueJianCe.GetValue(item.humidity)
        };

        dataList.push(dataObject);
      }
      this.tableData = dataList;
    },
    //切换Tab标签
    onClickTab: function (name, title) {
      this.GetDataList();
    },
    //进入统计页面
    GoToTongJiPage: function () {
      this.$router.push({
        name: 'TongJiPage'
      })
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
  overflow: auto;
}
.HuanJingContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(187, 187, 187, 100);
}
.TongJiView {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  font-family: Arial;
}
</style>