<template>
  <div class="BacePageView">
    <NavigationView :IsBack="false"
                    :IsCode="false"
                    Title='应用'></NavigationView>
    <div class="PageContent">
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('维保')">
            维保
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="Click('巡检')">
            巡检
          </div>
        </div>
      </div>
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('维修')">
            维修
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="Click('装修')">
            装修
          </div>
        </div>
      </div>
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('知识库')">
            知识库
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="Click('巡更')">
            巡更
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {

    }
  },
  methods: {
    Click: function (name) {
      if (name === '维保') {
        this.$router.push({
          name: 'WeiBaoPageMain'
        })
      }

      if (name === '巡检') {
        this.$router.push({
          name: 'XunJianPageMain'
        })
      }

      if (name === '维修') {
        this.$router.push({
          name: 'WeiXiuPageMain'
        })
      }

      if (name === '装修') {
        this.$router.push({
          name: 'ZhuangXiuPageMain'
        })
      }

      if (name === '知识库') {
        this.$router.push({
          name: 'ZhiShiPageMain'
        })
      }

      if (name === '巡更') {
        if (GlobleClass.UserData.isOrderDeal) {
          this.$router.push({
            name: 'XunGengPageMainXunGeng'
          })
        } else {
          this.$router.push({
            name: 'XunGengPageMainShenPi'
          })
        }
      }

    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
}
.GridCell {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-evenly;
}
.CellJianGe {
  height: 100%;
  width: 10%;
}
.CarView {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 30%;
}
.Content {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(187, 187, 187, 100);
  color: rgba(98, 93, 93, 100);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-semiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>