<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    :Title='name'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            累计耗电:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.LeJiDianHao}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            A相电流:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.AXiangDianLiu}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            B相电流:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.BXiangDianLiu}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            C相电流:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.CXiangDianLiu}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            A相电压:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.AXiangDianYa}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            B相电压:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.BXiangDianYa}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            C相电压:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.CXiangDianYa}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            有功功率:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.YouGongGongLv}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            无功功率:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.WuGongGongLv}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            功率因数:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.GongLvYinShu}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            频率:
          </div>
          <div class="CellContent_Cell_Content">
            {{biaoData.PinLv}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      name: this.$route.params.name,//电表名称
      biaoData: {//电表数据
        LeJiDianHao: 0,
        AXiangDianLiu: 0,
        BXiangDianLiu: 0,
        CXiangDianLiu: 0,
        AXiangDianYa: 0,
        BXiangDianYa: 0,
        CXiangDianYa: 0,
        YouGongGongLv: 0,
        WuGongGongLv: 0,
        GongLvYinShu: 0,
        PinLv: 0
      }
    }
  },
  mounted: function () {
    if (this.name === '1N51') {
      this.biaoData.LeJiDianHao = 2560
      this.biaoData.AXiangDianLiu = 10
      this.biaoData.BXiangDianLiu = 25
      this.biaoData.CXiangDianLiu = 5
      this.biaoData.AXiangDianYa = 380
      this.biaoData.BXiangDianYa = 380
      this.biaoData.CXiangDianYa = 380
      this.biaoData.YouGongGongLv = 21
      this.biaoData.WuGongGongLv = 0
      this.biaoData.GongLvYinShu = 0.9
      this.biaoData.PinLv = 50
    }
    if (this.name === '1N41') {
      this.biaoData.LeJiDianHao = 2660
      this.biaoData.AXiangDianLiu = 20
      this.biaoData.BXiangDianLiu = 35
      this.biaoData.CXiangDianLiu = 20
      this.biaoData.AXiangDianYa = 380
      this.biaoData.BXiangDianYa = 380
      this.biaoData.CXiangDianYa = 380
      this.biaoData.YouGongGongLv = 31
      this.biaoData.WuGongGongLv = 2
      this.biaoData.GongLvYinShu = 0.59
      this.biaoData.PinLv = 50
    }
    if (this.name === '1N61') {
      this.biaoData.LeJiDianHao = 3000
      this.biaoData.AXiangDianLiu = 30
      this.biaoData.BXiangDianLiu = 45
      this.biaoData.CXiangDianLiu = 30
      this.biaoData.AXiangDianYa = 380
      this.biaoData.BXiangDianYa = 380
      this.biaoData.CXiangDianYa = 380
      this.biaoData.YouGongGongLv = 51
      this.biaoData.WuGongGongLv = 5
      this.biaoData.GongLvYinShu = 0.39
      this.biaoData.PinLv = 50
    }
    if (this.name === '1N7-1') {
      this.biaoData.LeJiDianHao = 5000
      this.biaoData.AXiangDianLiu = 20
      this.biaoData.BXiangDianLiu = 55
      this.biaoData.CXiangDianLiu = 31
      this.biaoData.AXiangDianYa = 380
      this.biaoData.BXiangDianYa = 380
      this.biaoData.CXiangDianYa = 380
      this.biaoData.YouGongGongLv = 51
      this.biaoData.WuGongGongLv = 7
      this.biaoData.GongLvYinShu = 0.33
      this.biaoData.PinLv = 50
    }
    if (this.name === '1N7-2') {
      this.biaoData.LeJiDianHao = 3987
      this.biaoData.AXiangDianLiu = 60
      this.biaoData.BXiangDianLiu = 75
      this.biaoData.CXiangDianLiu = 54
      this.biaoData.AXiangDianYa = 380
      this.biaoData.BXiangDianYa = 380
      this.biaoData.CXiangDianYa = 380
      this.biaoData.YouGongGongLv = 97
      this.biaoData.WuGongGongLv = 9
      this.biaoData.GongLvYinShu = 0.99
      this.biaoData.PinLv = 50
    }
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
</style>