<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='扫一扫'></NavigationView>
    <div class="PageContent">
      <qrcode-drop-zone @decode="onDecode"
                        v-show="isShow">
        <qrcode-stream :qrcode="qrcode"
                       @decode="onDecode"
                       :torch="torchActive"
                       @init="onInit"
                       :camera="camera" />
      </qrcode-drop-zone>
      <div v-if="isShowContent"
           class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            仪器名称:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.codeName)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            使用说明:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.codeUsed)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            资产编码:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.code)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            RFID编码:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.codeRfid)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            设备型号:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.deviceCode)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            仪器负责人:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.person)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            联系方式:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.phone)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            使用次数(30日内):
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.usedCount)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            故障次数(30日内):
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.malCount)}}
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            状态:
          </div>
          <div class="CellContent_Cell_Content">
            {{jianCeObject.GetValue(codeContent.status)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import { QrcodeStream } from 'vue-qrcode-reader';
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'
export default {
  components: {
    NavigationView,
    QrcodeStream
  },
  data: function () {
    return {
      result: '', // 扫码结果信息
      error: '', // 错误信息
      qrcode: true,
      torchActive: false,
      camera: 'auto',
      isShow: true,
      isShowContent: false,
      codeContent: {

      },
      jianCeObject: ValueJianCe
    }
  },
  mounted: function () {

  },
  methods: {
    GetCodeContent: function () {
      this.isShow = false;
      this.isShowContent = true;
      let loading = GlobleClass.GetLoadingParam();
      let self = this; debugger
      NetWork.Post(URLConfig.getPicCodeList, {
        code: self.result
      }, (data) => {
        loading.close();
        if (data.data.err != null && data.data.err == 0 && data.data.items != null && data.data.items.length != 0) {
          self.codeContent = data.data.items[0];
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        loading.close();
        self.$message.error('加载失败');
      })
    },
    // 打开相机
    openCamera: function () {
      this.camera = 'rear'
      this.qrcode = true,
        this.isShow = true;
    },
    //关闭相机
    closeCamera: function () {
      this.camera = 'off'
      this.qrcode = false;
      this.isShow = false;
    },
    onDecode: function (result) {
      this.result = result;
      this.closeCamera();
      this.GetCodeContent();
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: 您需要授予相机访问权限"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: 这个设备上没有摄像头"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: 所需的安全上下文(HTTPS、本地主机)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: 相机被占用"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: 安装摄像头不合适"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: 此浏览器不支持流API"
        }
        alert(this.error);
      }
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}
.GridCell {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-evenly;
}
.CellJianGe {
  height: 100%;
  width: 10%;
}
.CarView {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 30%;
}
.Content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
</style>