<template>
  <div class="PageContent">
    <img :src="imageData"
         class="ImageView"
         @click="ShowImage" />
    <div style="width:16px"></div>
    <el-button icon="el-icon-delete-solid"
               circle
               @click="DeleteImage"></el-button>
    <div style="width:16px"></div>
    <el-button icon="el-icon-camera-solid"
               circle
               @click="SendImageMessage"></el-button>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
  components: {
    NavigationView,
    QrcodeStream
  },
  data: function () {
    return {
      imageData: '',
      UUID: ''
    }
  },
  mounted: function () {
    this.InitEvent();
  },
  beforeDestroy: function () {
    this.$EventBus.$off('ShowImageEnd');
  },
  methods: {
    //注册事件
    InitEvent: function () {
      let self = this;

      this.$EventBus.$on('ShowImageEnd', (uuid, imageData) => {
        debugger
        if (self.UUID == uuid) {
          self.imageData = 'data:image/png;base64,' + imageData;
          this.$emit('imageDataChange', self.imageData);
        }
      })
    },
    //展示照片
    ShowImage: function () {
      debugger
      if (this.imageData != '') {
        this.$EventBus.$emit('ShowImage', this.imageData);
      }
    },
    //删除照片
    DeleteImage: function () {
      this.imageData = '';
      this.$emit('imageDataChange', this.imageData);
    },
    //拍照
    SendImageMessage: function () {
      debugger
      //发送拍照的指令
      //获取当前的唯一标识
      this.UUID = new Date().getTime() + '';
      this.$EventBus.$emit('PaiZhaoMessage', this.UUID);
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ImageView {
  width: 45px;
  height: 45px;
  border: 1px slategray solid;
}
</style>