import axios from "axios";
import GlobleClass from "./GlobleClass";

export default class NetWork {

  /**
   * Post请求
   * @param {*} data 
   * @param {*} success 
   * @param {*} faile 
   */
  static Post (url, data, success, faile) {
    axios({
      method: "post",
      url: url,
      headers: this.GetHeader(),
      data: data
    })
      .then(function (resm) {
        success(resm);
      }).catch(function (err) {
        faile();
      })
  }

  static UpdateImage (url, baseString, fileName, success, faile) {
    let file = GlobleClass.base64ToFile(baseString, fileName);
    // 在这里进行一系列的校验
    const formData = new FormData();
    formData.append("file", file);
    axios({
      method: "post",
      url: url,
      headers: {
        SIGN_KEY: "f092a10dc2399f56ddea88cdad3241bd",
        APP_KEY: "LEMS",
        'Content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then(function (res) {
        success(res);
      }).catch(function (err) {
        faile();
      })

  }

  /**
   * 获取请求头
   * @returns 
   */
  static GetHeader () {
    return {
      SIGN_KEY: "f092a10dc2399f56ddea88cdad3241bd",
      APP_KEY: "LEMS"
    }
  }

}