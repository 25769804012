<template>
  <div class="BacePageView">
    <NavigationView :IsBack="false"
                    :IsCode="false"
                    Title='能耗'></NavigationView>
    <div class="PageContent">
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('1N51')">
            1N51
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="Click('1N41')">
            1N41
          </div>
        </div>
      </div>
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('1N61')">
            1N61
          </div>
        </div>
        <div class="CarView">
          <div class="Content"
               @click="Click('1N7-1')">
            1N7-1
          </div>
        </div>
      </div>
      <div class="GridCell">
        <div class="CarView">
          <div class="Content"
               @click="Click('1N7-2')">
            1N7-2
          </div>
        </div>
        <div class="CarView">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
export default {
  components: {
    NavigationView
  },
  data: function () {
    return {

    }
  },
  methods: {
    Click: function (name) {
      this.$router.push({
        name: 'NengHaoContent', params: {
          name: name
        }
      })
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
}
.GridCell {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-evenly;
}
.CellJianGe {
  height: 100%;
  width: 10%;
}
.CarView {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 30%;
}
.Content {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(187, 187, 187, 100);
  color: rgba(98, 93, 93, 100);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-semiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>