<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='新建工单'></NavigationView>
    <div class="PageContent">
      <div class="Content">
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            对象系统:
          </div>
          <div class="CellContent_Cell_Content">
            <el-select v-model="OrderModel.orderSys"
                       placeholder="请选择">
              <el-option v-for="item in objectSystem"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            对象名称:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.deviceName"
                      placeholder="请输入名称"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            位置描述:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.pointName"
                      placeholder="请输入描述信息"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            报警原因:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.orderReason"
                      placeholder="请输入报警原因"></el-input>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            报警等级:
          </div>
          <div class="CellContent_Cell_Content">
            <el-select v-model="OrderModel.orderLevel"
                       placeholder="请选择">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            拍照:
          </div>
          <div class="CellContent_Cell_Content">
            <ShowImageView @imageDataChange="imageDataChange"></ShowImageView>
          </div>
        </div>
        <div class="CellContent_Cell">
          <div class="CellContent_Cell_Title">
            工单备注:
          </div>
          <div class="CellContent_Cell_Content">
            <el-input v-model="OrderModel.orderRemark"
                      placeholder="请输入备注信息"></el-input>
          </div>
        </div>
      </div>
      <div class="ButtonView">
        <el-button type="primary"
                   class="ChuLiButtonView"
                   v-loading.fullscreen.lock="fullscreenLoading"
                   element-loading-text="正在提交"
                   element-loading-background="rgba(0, 0, 0, 0.2)"
                   @click="InputOrder">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import GlobleClass from '@/components/ToolClass/GlobleClass'
import ShowImageView from '@/components/ToolView/ShowImageView/ShowImageView'
export default {
  components: {
    NavigationView,
    ShowImageView
  },
  data: function () {
    return {
      options: [],
      objectSystem: [],
      OrderModel: {
        createStaff: GlobleClass.UserData.userId,
        createTime: new Date().Format('yyyy-MM-dd'),//创建时间 ,
        deviceName: '',//对象名称 ,
        orderLevel: '通知',//诊断等级:1通知，2：普通，3：重要，4：严重 ,
        orderReason: '',//报警原因 ,
        orderRemark: '',//备注 ,
        orderSys: '新风系统',//工单系统:1门禁，2电梯，3排风，4新风，5气体，6环境，7消防，8视频 ,
        pointName: '',//点位描述
        isDeal: '1'
      },
      imageData: "",
      fullscreenLoading: false
    }
  },
  mounted: function () {
    let self = this;
    GlobleClass.GetDengJi((data) => {
      self.options = data;
    });
    GlobleClass.GetAllSystem(false, (data) => {
      self.objectSystem = data;
    });
  },
  methods: {
    imageDataChange: function (imageData) {
      this.imageData = imageData;
    },
    //提交工单
    InputOrder: function () {
      //数据验证
      if (this.OrderModel.deviceName === null || this.OrderModel.deviceName === '') {
        this.$message.error('请输入对象名称');
        return;
      }
      if (this.OrderModel.pointName === null || this.OrderModel.pointName === '') {
        this.$message.error('请输入位置描述');
        return;
      }
      debugger
      for (let index = 0; index < this.objectSystem.length; index++) {
        const element = this.objectSystem[index];
        if (element.label == this.OrderModel.orderSys) {
          this.OrderModel.orderSys = element.value;
          break;
        }
      }

      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index];
        if (element.label == this.OrderModel.orderLevel) {
          this.OrderModel.orderLevel = element.value;
          break;
        }
      }


      this.fullscreenLoading = true;
      let self = this;

      if (this.imageData != null && this.imageData != '') {
        //上传图片
        NetWork.UpdateImage(URLConfig.uploadFile, this.imageData, 'fileName', (res) => {
          self.fullscreenLoading = false;
          if (res.data.err !== null && res.data.err == 0) {
            this.OrderModel.fileId = res.data.items.uploadFileId;
            self.InputOrder_1();
          } else {
            self.$message.error('图片上传失败');
          }
        }, () => {
          self.fullscreenLoading = false;
          self.$message.error('图片上传失败');
        })
      } else {
        this.InputOrder_1();
      }

    },
    InputOrder_1: function () {
      let self = this;
      NetWork.Post(URLConfig.addOrder, this.OrderModel, (res) => {
        if (res.data.err !== null && res.data.err == 0) {//成功
          self.fullscreenLoading = false;
          self.$message({
            message: '新建成功',
            type: 'success'
          });
          self.$router.go(-1);
          self.$EventBus.$emit('ReloadData');
        } else {
          self.fullscreenLoading = false;
          self.$message.error('提交失败');
        }
      }, () => {
        debugger
        self.fullscreenLoading = false;
        self.$message.error('提交失败');
      })
    }
  }
}
</script>

<style scoped>
.Content {
  flex: 1;
}
.ButtonView {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CellContent_Cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  position: relative;
}
.CellContent_Cell_Title {
  margin-left: 16px;
  width: 100px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.CellContent_Cell_Content {
  flex: 1;
  margin-right: 16px;
  color: #101010;
  font-size: 10px;
  text-align: left;
  font-family: Microsoft Yahei;
}
.ChuLiButtonView {
  width: 100px;
  height: 30px;
  background-color: rgba(56, 148, 255, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 12px;
  border: 1px solid rgba(56, 148, 255, 100);
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  font-size: 10px;
}
</style>