<template>
  <div class="BacePageView">
    <NavigationView :IsBack="true"
                    :IsCode="false"
                    Title='统计'></NavigationView>
    <div class="PageContent">
      <div class="ChartView">
        <div class="ChartViewTitle">
          能源统计
        </div>
        <div id="myChart"
             class="ChartViewConent">
        </div>
        <div>本月累计电耗</div>
      </div>
      <div class="ChartView">
        <div class="ChartViewTitle">
          环境统计
        </div>
        <div id="myChart_1"
             class="ChartViewConent">

        </div>
        <div>今日故障累计</div>
      </div>
    </div>
  </div>
</template>

<script>

import NavigationView from '@/components/ToolView/NavigationView/NavigationView'
import * as echarts from 'echarts';
import NetWork from '@/components/ToolClass/NetWork'
import URLConfig from '@/components/ToolClass/URLConfig'
import ValueJianCe from '@/components/ToolClass/ValueJianCe'
import GlobleClass from '@/components/ToolClass/GlobleClass'

export default {
  components: {
    NavigationView
  },
  data: function () {
    return {
      option: {
        title: {
          text: '--/rkwh',
          subtext: "kWh",
          left: 'center',
          top: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'center',
          bottom: 0
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,

            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: [

            ]
          }
        ]
      },
      option_1: {
        title: {
          text: '--',
          left: 'center',
          top: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'center',
          bottom: 0,
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,

            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: [

            ]
          }
        ]
      }
    }
  },
  mounted: function () {
    //能耗统计
    this.GetNengYuanTongJiData();
    //环境统计
    this.GetHuanJingTongJiData();
  },
  methods: {
    //获取能耗统计信息
    GetNengYuanTongJiData: function () {
      let self = this;
      NetWork.Post(URLConfig.getEnergyCount, {

      }, (res) => {
        if (res.data.err !== null && res.data.err == 0) {
          let allTypes = res.data.items.energyTypes;
          if (allTypes != null) {
            debugger
            let allItems = [];
            for (let index = 0; index < allTypes.length; index++) {
              const item = allTypes[index];
              allItems.push({
                value: ValueJianCe.GetValue(item.value).replace('Kwh', ''),
                name: ValueJianCe.GetValue(item.type)
              });
            }
            self.option.title.text = res.data.items.allCount;
            self.option.series[0].data = allItems;
            let chartDom = document.getElementById('myChart');
            let myChart = echarts.init(chartDom, null, {
              renderer: 'canvas',
              useDirtyRect: false
            });
            myChart.setOption(self.option);
          } else {
            self.$message.error('加载失败');
          }
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        self.$message.error('加载失败');
      })
    },
    //获取环境统计信息
    GetHuanJingTongJiData: function () {
      let self = this;
      NetWork.Post(URLConfig.getWarningType, {

      }, (res) => {
        if (res.data.err !== null && res.data.err == 0) {
          let allTypes = res.data.items.list;
          if (allTypes != null) {
            debugger
            let allItems = [];
            for (let index = 0; index < allTypes.length; index++) {
              const item = allTypes[index];
              allItems.push({
                value: ValueJianCe.GetValue(item.COUNT),
                name: ValueJianCe.GetValue(item.POINT_DESC)
              });
            }
            self.option_1.title.text = res.data.items.allCount;
            self.option_1.series[0].data = allItems;
            let chartDom = document.getElementById('myChart_1');
            let myChart = echarts.init(chartDom);
            myChart.setOption(this.option_1);
          } else {
            self.$message.error('加载失败');
          }
        } else {
          self.$message.error('加载失败');
        }
      }, () => {
        self.$message.error('加载失败');
      })
    }
  }
}
</script>

<style scoped>
.PageContent {
  display: flex;
  flex: 1;
  overflow: auto;
}
.ChartView {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ChartViewTitle {
  padding-left: 16px;
  color: #707070;
  font-size: 10px;
  text-align: center;
  font-family: Arial;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid #707070;
}
.ChartViewConent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 480px;
}
</style>